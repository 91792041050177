@media (max-width: 1199px) {
  .sm-hidden {
    visibility: hidden;
    height: 0vh;
  }

  .m-block {
    display: block !important;
  }

  .logo {
    width: 30%;
  }
  /* font size */
  .sm-font-12px {
    font-size: 12px !important;
  }
  .sm-font-14px {
    font-size: 14px !important;
  }
  .sm-font-16px {
    font-size: 16px !important;
  }
  .sm-font-18px {
    font-size: 18px !important;
  }
  .sm-font-20px {
    font-size: 20px !important;
  }
  .sm-font-26px {
    font-size: 26px !important;
  }
  .sm-font-30px {
    font-size: 30px !important;
  }
  .sm-font-35px {
    font-size: 35px !important;
  }
  .sm-font-40px {
    font-size: 40px !important;
  }
  .sm-font-50px {
    font-size: 50px !important;
  }
  .sm-font-60px {
    font-size: 60px !important;
  }
  .sm-font-70px {
    font-size: 70px !important;
  }
  .sm-font-80px {
    font-size: 80px !important;
  }
  .sm-font-90px {
    font-size: 90px !important;
  }
  .sm-font-100px {
    font-size: 100px !important;
  }
  .sm-font-120px {
    font-size: 120px !important;
  }
  .sm-font-130px {
    font-size: 130px !important;
  }

  /* bg */
  .fp-section {
    background-color: rgba(255, 255, 255, 0.541) !important;
  }

  .fp-table {
    height: auto !important;
  }
  .loc-img1,
  .loc-img2,
  .loc-img3,
  .loc-img4,
  .img1,
  .img2,
  .img3,
  .img4 {
    width: 107%;
    height: 200px;
  }

  /* margin */
  .sm-mt--10 {
    margin-top: -48% !important;
  }

  .sm-mt-10 {
    margin-top: 10%;
  }

  .sm-mb-10 {
    margin-bottom: 10%;
  }

  .sm-plr-10 {
    padding-right: 10%;
    padding-left: 10%;
  }

  .sm-mlr-5 {
    margin-right: 5%;
    margin-left: 5%;
  }

  .sm-mt-5 {
    margin-top: 5%;
  }

  .sm-text-center {
    text-align: center;
  }

  /* carrousel */

  .carousel-cueilleur .swiper-container {
    transform: rotate(90deg);
    height: 60vh;
  }
  .carousel-cueilleur .swiper-slide {
    transform: rotate(180deg);
  }
  .carousel-cueilleur .swiper-slide img {
    transform: rotate(90deg);
    display: block;
    object-fit: cover;
  }

  .videoTag {
    height: 100vh;
    top: 0;
    width: 300vw;
    left: -150%;
    position: fixed; /* optional depending on what you want to do in your app */
  }
}
