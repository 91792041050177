/* base */

.nav {
  display: block;
}

#header {
  padding: 2rem;
  /* background: red;

  height: 7em; */
}

#menu {
  background: #e2e9f1;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: fixed;
  text-align: center;
  top: 0;
  transition: all 0.7s ease-in-out;
  width: 100%;
  z-index: -99;
}

/*  menu ouvert */

#menu.open {
  opacity: 0.95;
  visibility: visible;
  z-index: 99;
}
#menu.open li:nth-child(1) {
  animation-delay: 0.1s;
}
#menu.open li:nth-child(2) {
  animation-delay: 0.2s;
}
#menu.open li:nth-child(3) {
  animation-delay: 0.3s;
}
#menu.open li:nth-child(4) {
  animation-delay: 0.4s;
}
#menu.open li:nth-child(5) {
  animation-delay: 0.5s;
}
#menu.open li:nth-child(6) {
  animation-delay: 0.6s;
}
#menu.open li:nth-child(7) {
  animation-delay: 0.7s;
}
#menu.open li:nth-child(8) {
  animation-delay: 0.8s;
}
#menu.open li:nth-child(9) {
  animation-delay: 0.9s;
}
#menu.open li:nth-child(10) {
  animation-delay: 1s;
}
#menu.open li {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeInUp;
}
/* lien */
#menu ul:hover a {
  opacity: 0.5;
}
#menu ul a {
  transform: translateZ(0);
  transition: opacity 0.3s ease-in-out;
}
#menu ul a:hover {
  opacity: 1;
}

/* icones */
#menu .main-nav {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
#menu .main-nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#menu .main-nav a {
  color: #82293b;
  display: block;
  font-size: 19px;
  font-weight: bold;
  padding: 1rem 1rem;
  text-decoration: none;
  text-transform: uppercase;
}
#menu .main-nav a:first {
  padding-top: 0;
}

/* footer menu  */
#menu .menu-footer {
  padding: 2rem;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
}
#menu .menu-footer ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}
#menu .menu-footer ul li {
  display: inline;
  margin: 0 1rem;
}
#menu .menu-footer a {
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 2vh;
}

/* boutons toggle */
.toggle-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 50px;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: 50px;
  z-index: 1110;
}
.toggle-menu span {
  background-color: #82293b;
  content: "";
  display: block;
  height: 3px;
  left: calc(50% - 13px);
  position: absolute;
  top: calc(50% - 1px);
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  width: 26px;
}
.toggle-menu span:before,
.toggle-menu span:after {
  background-color: #82293b;
  content: "";
  display: block;
  height: 3px;
  position: absolute;
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
}
.toggle-menu span:before {
  top: 7px;
  width: 13px;
}
.toggle-menu span:after {
  top: -7px;
  width: 13px;
  right: 0px;
}

/* bouton toggle ouvert */

#button.open span {
  background-color: transparent;
  transition: background 0.2s ease-out;
}
#button.open span:before {
  transition: top 0.2s ease-out;
}
#button.open span:after {
  transform: 0.2s 0.2 ease-out;
}
#button.open span:before {
  top: 0;
  transform: rotate3d(0, 0, 1, -45deg);
  width: 26px;
}
#button.open span:after {
  top: 0;
  transform: rotate3d(0, 0, 1, 45deg);
  width: 26px;
}

/* icones */
#insta {
  width: 2vh;
  height: 2vh;
  color: #82293b;
  margin-right: 3px;
}

#Facebook {
  color: #82293b;
  width: 2vh;
  height: 2vh;
}
#youtube {
  color: #82293b;
  width: 2vh;
  height: 2vh;
  margin-right: 3px;
}

#Linkedin {
  color: #82293b;
  width: 2vh;
  height: 2vh;
  margin-right: 3px;
}

.shop {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: right;
  height: 50px;
  outline: none;
  padding: 0;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  width: 50px;
  z-index: 2;
}
#shop {
  color: #82293b;
  width: 3vh;
  height: 3vh;
}

#compte {
  color: #82293b;
  width: 3vh;
  height: 3vh;
}

/* ANIMATION */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* @media (max-width: 1000px) {
  .toggle-menu {
    width: 150px;
    height: 70px;
  }
  .toggle-menu span {
    height: 9px;
    width: 60px;
  }
  .toggle-menu span:before,
  .toggle-menu span:after {
    height: 9px;
  }
  .toggle-menu span:before {
    top: 20px;
    width: 20px;
  }
  .toggle-menu span:after {
    top: -20px;
    width: 20px;
  }

  #shop {
    width: 3vh;
    height: 3vh;
  }
} */
